.video-container {
    position: absolute;
    top: 0;
    bottom: 0;
    /* width: 100%;
    height: 100%;  */
    overflow: hidden;
  }
  .video-container video {
    /* Make video to at least 100% wide and tall */
    /* min-width: 100%; 
    min-height: 100%;  */
  
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    /* width: auto;
    height: auto;  */
  
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-54%);
    /* width:100%; height:100%; */
    object-fit: contain;
  }
  .camera-button {
    position: absolute !important;
    bottom: 10% !important;
    left: 50% !important;
    transform: translate(-50%,-5%) !important;
  }
  .flash-button {
    position: absolute !important;
    bottom: 12% !important;
    left: 90% !important;
    transform: translate(-80%,-10%) !important;
  }
  .gallery-button {
    position: absolute !important;
    bottom: 12% !important;
    left: 10% !important;
    transform: translate(-20%,-10%) !important;
  }
  .hold-horizonal-alert {
    position: absolute !important;
    margin-top: 10vh !important;

  }
  .error-alert {
    position: absolute !important;
    margin-top: 20vh !important;

  }

  .video-container canvas {
    /* Make video to at least 100% wide and tall */
    min-width: 50%; 
    min-height: 50%; 
  
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    display: none;
  
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 1;
  }

  .img-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    background-color: black;
  }
  .img-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-54%);
    width:100%; height:100%;
    object-fit: contain;
    margin-bottom: 10vh;
  }