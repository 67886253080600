/* the colored 'MyFoodPrint' at the top of the screen */

.appName {
    color: #91C788;
    display: flex;
    justify-content: center;
}

/* the images used to explain the working of the app */

.exampleIcon {
    max-width: 40%; 
}

.exampleImage {
    max-width: 100%; 
}

.exampleLegend {
    /* display: flex;
    justify-content: center; */
    margin: 1rem 0;
}

.FAQheader {
    line-height: 1.2em !important;
    margin: 1em 0 !important;
    display: inline-block;
}

.FAQtext {
    margin-bottom: .5em !important;
}